import React from 'react'
import Layout from '../components/layout'
import EditProfile from '../components/editProfile/component'

const EditProfilePage = props => (
  <Layout>
    <EditProfile />
  </Layout>
)

export default EditProfilePage
