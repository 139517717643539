import React, { Component } from 'react'
import { Link, navigate } from 'gatsby'
import axios from 'axios'
import _ from 'lodash'
import CancelButton from './../../assets/images/cancel _btn.png'
import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
} from 'reactstrap'
import Cookies from 'universal-cookie'
import NumberFormat from 'react-number-format'
import { HOSTNAME } from '../config'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import PlacesAutocomplete, {
  geocodeByAddress,
  getPlace,
  getLatLng,
  geocodeByPlaceId,
} from 'react-places-autocomplete'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
const cookies = new Cookies()

let initialState = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  err: {},
  userId: '',
  isLoading: false,
  dob: null,
  gender: '',
  address: '',
}

class DatePickerCustomInput extends React.Component {
  render() {
    return (
      <button
        className="form-control date__picker text-left"
        onClick={this.props.onClick}
      >
        {this.props.value}
      </button>
    )
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string,
}

class EditProfile extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
    this.handleChange = this.handleChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleAddressChange = this.handleAddressChange.bind(this)
    this.handleAddressSelect = this.handleAddressSelect.bind(this)
    this.goBack = this.goBack.bind(this)
  }

  componentDidMount() {
    const user = cookies.get('user')
    if (!user || user === 'undefined') {
      navigate('/login')
    } else {
      this.setState({
        userId: _.get(user, '_id', ''),
        firstName: _.get(user, 'firstName', ''),
        lastName: _.get(user, 'lastName', ''),
        email: _.get(user, 'email', ''),
        phoneNumber: _.get(user, 'phoneNumber', ''),
        dob: _.get(user, 'dob', null),
        gender: _.get(user, 'gender', ''),
        address: _.get(user, 'address', ''),
      })
      setTimeout(function() {
        document.title = 'Edit Profile | American Field'
        let meta = document.getElementsByTagName('meta')
        for (var i = 0; i < meta.length; i++) {
          if (meta[i].name.toLowerCase() === 'description') {
            meta[i].content = 'Edit Profile | American Field'
          }
          if (meta[i].name.toLowerCase() === 'url') {
            meta[i].content = 'https://app.shopaf.co/edit-profile'
          }
        }
      }, 300)
    }
  }

  handleChange(e) {
    if (e.target.name === 'firstName') {
      this.state.err.firstName = ''
    }
    if (e.target.name === 'lastName') {
      this.state.err.lastName = ''
    }
    this.setState({ [e.target.name]: e.target.value })
  }

  handleDateChange(date) {
    this.setState({ dob: date })
  }

  handleAddressChange(address) {
    this.setState({ address: address })
  }

  handleAddressSelect(address) {
    geocodeByAddress(address)
      .then(results => this.setState({ address: results[0].formatted_address }))
      .then(latLng => console.log())
      .catch(error => console.error('Error', error))
  }

  submitForm(event) {
    event.preventDefault()
    const {
      userId,
      firstName,
      lastName,
      email,
      phoneNumber,
      dob,
      gender,
      address,
    } = this.state
    let self = this
    const err = {}
    if (firstName === '' || firstName.trim() === '') {
      err.firstName = 'Please enter your first name'
    }
    if (lastName === '' || lastName.trim() === '') {
      err.lastName = 'Please enter your last name'
    }
    this.setState({ err })
    if (!Object.keys(err).length) {
      window &&
        window.ga &&
        window.ga('send', 'event', {
          eventCategory: 'Edit Profile',
          eventAction: 'Edit profile submit clicked',
          eventLabel: 'Edit profile submit clicked',
          transport: '1',
        })
      this.setState({ isLoading: true })
      axios
        .post(HOSTNAME + 'updateUser', {
          userId: userId,
          firstName: firstName,
          lastName: lastName,
          dob: dob,
          gender: gender,
          address: address,
        })
        .then(function(response) {
          let res = response.data
          if (res.success) {
            self.setState({ isLoading: false })
            let expires = new Date()
            expires.setHours(expires.getHours() + 24)
            expires = new Date(expires)
            cookies.set('user', res.data, { path: '/', expires })
          } else {
            self.setState({ isLoading: false })
          }
        })
        .catch(function(error) {
          self.setState({ isLoading: false })
        })
    }
  }

  goBack() {
    window.history.back()
  }
  render() {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      err,
      isLoading,
      dob,
      address,
      gender,
    } = this.state
    return (
      <div>
        <div className="edit__profile--page page">
          <section className="edit__profile--header">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="edit__header--inner">
                    <div className="edit__title">Edit Profile</div>
                    <div onClick={this.goBack} className="edit__close--btn">
                      <img src={CancelButton} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="form">
                    <FormGroup row>
                      <Label for="exampleEmail" sm={4}>
                        FIRST NAME
                      </Label>
                      <Col sm={12}>
                        <Input
                          type="text"
                          name="firstName"
                          value={firstName}
                          onChange={this.handleChange}
                          placeholder=""
                        />
                        <FormFeedback>
                          {'firstName' in err ? err.firstName : ''}{' '}
                        </FormFeedback>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="exampleEmail" sm={4}>
                        LAST NAME
                      </Label>
                      <Col sm={12}>
                        <Input
                          type="text"
                          name="lastName"
                          value={lastName}
                          onChange={this.handleChange}
                          placeholder=""
                        />
                        <FormFeedback>
                          {'lastName' in err ? err.lastName : ''}{' '}
                        </FormFeedback>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="exampleEmail" sm={4}>
                        EMAIL
                      </Label>
                      <Col sm={12}>
                        <Input
                          type="text"
                          defaultValue={email}
                          placeholder=""
                          readOnly="true"
                        />
                      </Col>
                    </FormGroup>
                    {/*<FormGroup row>
                      <Label for="exampleEmail" sm={4}>
                        PHONE NUMBER
                      </Label>
                      <Col sm={12}>
                        <NumberFormat
                          className="form-control"
                          value={phoneNumber}
                          format="(###) ###-####"
                          mask="_"
                        />
                      </Col>
                    </FormGroup>*/}
                    <FormGroup row>
                      <Label for="exampleEmail" sm={4}>
                        BIRTHDAY
                      </Label>
                      <Col sm={12}>
                        <DatePicker
                          customInput={<DatePickerCustomInput />}
                          className="form-control"
                          selected={dob ? dob : null}
                          onChange={this.handleDateChange}
                          maxDate={new Date()}
                          placeholderText="Pick a date"
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="exampleEmail" sm={4}>
                        GENDER
                      </Label>
                      <Col sm={12}>
                        <Input
                          type="select"
                          name="gender"
                          value={gender}
                          onChange={this.handleChange}
                        >
                          <option>&nbsp;</option>
                          <option>Male</option>
                          <option>Female</option>
                        </Input>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="exampleEmail" sm={4}>
                        ADDRESS
                      </Label>
                      <Col sm={12}>
                        <PlacesAutocomplete
                          value={address}
                          className="form-control text-input"
                          onChange={this.handleAddressChange}
                          onSelect={this.handleAddressSelect}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <input
                                {...getInputProps({
                                  placeholder: 'Enter Address',
                                  className:
                                    'form-control text-input addressAutoSuggest',
                                })}
                              />
                              <div
                                className={'autocomplete-dropdown-container'}
                              >
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                  const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item'

                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </Col>
                    </FormGroup>
                    {isLoading ? (
                      <Button className="btn btn-primary btn-lg btn-block edit__update--btn">
                        Requesting...
                      </Button>
                    ) : (
                      <Button
                        className="btn btn-primary btn-lg btn-block edit__update--btn"
                        onClick={this.submitForm.bind(this)}
                      >
                        UPDATE
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }
}

export default EditProfile
